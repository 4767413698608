<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button size="sm" variant="primary" @click="() => loadData(false)">
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>
      <h6 v-show="gridData.newNotifications" class="mb-3">Новых уведомлений: {{ gridData.newNotifications }}</h6>
      <Grid
        ref="requestsGrid"
        :items="gridData.notifications"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      >
        <template v-slot:notification="{ notification }">
          <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="viewChanges(notification.Id, notification.IsNew)"
          >
            Просмотреть
          </button>
          <b-modal
            :ref="notification.Id"
            size="lg"
            scrollable
            ok-only
            title="Просмотр изменения"
          >
            <div class="d-block text-center">
              <NotificationData :data="notification" :loading="isChangeProcessing"/>
            </div>
          </b-modal>
        </template>

      </Grid>

  <!--    <b-modal
        ref="memberInfoModal"
        size="lg"
        scrollable
        ok-only
        title="Данные о игроке"
      >
        <div class="d-block text-center">
          <MemberData
            v-if="typeof selectedMember.Id !== 'undefined'"
            ref="memberData"
            :member="selectedMember"
            :baseURL="baseURL"
            :images="selectedMemberImages()"
            :getRank="getRank"
          ></MemberData>
        </div>
      </b-modal>-->
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import API from "../../api/stalk.net";
  import Grid from "../../components/common/Grid";
  import NotificationData from "../../components/NotificationData";
  import MemberData from "../../components/MemberData";

  export default {
    name: "Notifications",
    components: {
      Grid,
      NotificationData,
      MemberData
    },
    data() {
      return {
        isPageLoading: false,
        isChangeProcessing: false,
        gridData: {
          newNotifications: 0,
          notifications: [],
          columns: [
            // {
            //   title: "ID",
            //   path: "Id"
            // },
            {
              title: "Позывной",
              path: "Target.Callsign",
              // action: this.viewUserData
            },
            {
              title: "Тип изменения",
              path: "Type",
              handler: str => {
                return API.getActionTypeTitle(str);
              }
            },
            {
              title: "Кем прочитано",
              path: "ProcessedBy.Email"
            },
            {
              title: "Дата изменения",
              path: "UpdatedAt",
              handler: str => {
                return Vue.filter("formatDate")(str);
              }
            }
          ],
          index: {title: {path: "Target.Callsign"}, id: {path: "Target.User.Id"}},
          classes: [
            {
              path: "IsNew",
              equals: true,
              type: "table-green"
            }
          ],
          actions: [],
          searchQuery: "",
          sortBy: {}
        },
        selectedUser: {},
        selectedMember: {},
        baseURL: API.baseURL
      };
    },
    methods: {
      loadData(sort = true) {
        let self = this;

        self.isPageLoading = true;
        API.private
          .getNewNotificationsCount()
          .then(resp => {
            self.gridData.newNotifications = resp.data;
          })
          .catch(this.catchError);

        API.private
          .getNotifications()
          .then(resp => {
            self.gridData.notifications = resp.data;

            if (sort) {
              self.gridData.sortby = {title: "Id", path: "Id"};
              self.$refs.requestsGrid.sortBy(self.gridData.sortby);
            }
            self.isPageLoading = false;
          })
          .catch(this.catchError);
      },
      viewChanges(id, isNew) {
        this.$refs[id].show()

        let viewedNotification = this.gridData.notifications.find(el => el.Id === id)
        viewedNotification.IsNew = false
        // let idx = this.gridData.notifications.findIndex(el => el.Id === id)
        // const changedArr = [ ...this.gridData.notifications.slice(0,idx-1), viewedNotification, ...this.gridData.notifications.slice(idx+1,this.gridData.notifications.length ) ];
        // this.gridData.notifications = changedArr
        if(isNew){
          this.isChangeProcessing = true;
          API.private
            .getNotificationById(id)
            .then(() => {
              this.isChangeProcessing = false;
              // this.loadData()
              this.showProcessSuccess();

            })
            .catch(this.catchError);
        }
      },
      // viewUserData(rid) {
      //   let self = this;
      //   API.private
      //     .getMemberByRequest(rid)
      //     .then(resp => {
      //       self.selectedMember = resp.data;
      //       self.$refs.memberInfoModal.show();
      //     })
      //     .catch(self.catchError);
      // },
      catchError(e) {
        let self = this;

        console.error(e);
        if (e.response && e.response.data && e.response.data.error) {
          console.error(e.response.data);
          switch (e.response.data.error) {
            case "ERR_BAD_JWT":
            case "ERR_BAD_AUTH":
            case "ERR_NOT_ALLOWED": {
              self.$router.replace("/login");
            }
              break;
          }
          self.showError({message: e.response});
        }
        self.isPageLoading = false;
      },
      getRank(rank) {
        return API.getRankTitle(rank);
      },
      selectedMemberImages() {
        let self = this;
        let images = [];
        if (!self.selectedMember.Photos) return null
        self.selectedMember.Photos.forEach(photo => {
          images.push(self.baseURL + photo.Path);
        });

        return images;
      }
    },
    beforeMount: function () {
      this.loadData();
    },
    notifications: {
      showError: {
        title: "Ошибка",
        type: "error"
      },
      showProcessSuccess: {
        title: "Изменение помечено как прочитанное",
        type: "success"
      }
    }
  };
</script>
